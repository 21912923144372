.App {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

button {
	padding: 8px;
}

p {
	margin-top: 4px;
	text-align: center;
}
