:root {
	--WALL: rgb(12, 53, 71);
	--BORDER: 1px solid var(--WALL);
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-ms-user-select: none;
	-webkit-user-select: none;
	user-select: none;
}

button,
input {
	font-size: inherit;
	font-weight: inherit;
	font-family: inherit;
}
body {
	font-family: "Roboto", sans-serif;
	font-size: 16px;
}
